import { isEnglish } from '@ipgd-gauge/utils';
import SelectArrowIcon from '../../../../../../icons/SelectArrow';
import style from './style.module.scss';

const AttachmentTypeStatusMenu = (props) => {
    const { id, options, value, onChange, onKeyDown, disabled } = props;

    const _getSelectedLabel = () => {
        let selectedOption = options.filter((option) => Number(option.id) === Number(value));
        if (selectedOption.length > 0) {
            selectedOption = selectedOption[0];
            return isEnglish() ? selectedOption.descLo : selectedOption.descFo;
        }
    }

    const _handleOpenMenu = (e) => {
        e.stopPropagation();
        const openedMenus = [...document.getElementsByClassName("opened")].filter(item => item.id.includes("-options-container") && item.id != `${id}-select-menu-options-container`);
        [...openedMenus].forEach(element => element.classList.remove("opened"));
        document.getElementById(`${id}-select-menu`).focus();
        document.getElementById(`${id}-select-menu-options-container`)?.classList?.toggle("opened");
    }

    const _onChange = (statusId) => {
        if (onChange) {
            onChange(statusId);
        }
        document.getElementById(`${id}-select-menu-options-container`)?.classList?.remove("opened");
    }

    const _onKeyDown = (event) => {
        if (event.key === "Escape") {
            document.getElementById(`${id}-select-menu-options-container`)?.classList?.remove("opened");
            return;
        }
        if (document.getElementById(`${id}-select-menu-options-container`)?.classList.contains("opened")) {
            if (event.key === "ArrowDown") {
                document.getElementById(`attendance-status-option-${1}-${id}`).focus();
                return;
            }
        }
        if (onKeyDown) {
            onKeyDown(event);
        }
    }

    const _onOptionKeyDown = (event, sequance) => {
        if (event.key === "Escape") {
            document.getElementById(`${id}-select-menu-options-container`)?.classList?.remove("opened");
            document.getElementById(`${id}-select-menu`)?.focus();
            return;
        }
        let nextElement = null;
        if (event.key === "ArrowDown") {
            nextElement = document.getElementById(`attendance-status-option-${sequance + 1}-${id}`);
        } else if (event.key === "ArrowUp") {
            nextElement = document.getElementById(`attendance-status-option-${sequance - 1}-${id}`);
        }
        if (nextElement) {
            event.preventDefault();
            nextElement.focus();
        }
    }

    return (
        <>
            <button id={`${id}-select-menu`} key={id} title={_getSelectedLabel()} className={style.valueContainer} onClick={_handleOpenMenu} onKeyDown={_onKeyDown} disabled={disabled}>
                <div>
                    <div className={`type-${value}`}></div>
                    <label id={`${id}-select-menu-label`}>{_getSelectedLabel()}</label>
                </div>
                <span id={`${id}-select-menu-icon`}><SelectArrowIcon /></span>
            </button>
            <div id={`${id}-select-menu-options-container`} className={style.optionsContainer}>
                {(options || []).map((option, index) =>
                    <button
                        id={`attendance-status-option-${index + 1}-${id}`}
                        className={option.id == value ? "selected" : ""}
                        onClick={() => _onChange(option.id)}
                        onKeyDown={(event) => _onOptionKeyDown(event, index + 1)}
                    >
                        {isEnglish() ? option.descLo : option.descFo}
                    </button>
                )}
            </div>
        </>
    )
}

AttachmentTypeStatusMenu.defaultProps = { id: Date.now() };

export default AttachmentTypeStatusMenu;