import React from "react";

function SelectArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346.25 192.25"
    width="12.25"
      height="12.25">
      <path
        fill="#668fff"
        d="M340.66 5.59c-7.45-7.45-19.53-7.45-26.99 0L173.12 146.14 32.57 5.59c-7.45-7.45-19.53-7.45-26.98 0s-7.45 19.53 0 26.98l153.16 153.16c.28.31.54.63.84.93 3.74 3.74 8.64 5.6 13.54 5.59 4.9.01 9.8-1.85 13.54-5.59.3-.3.57-.62.84-.93L340.66 32.57c7.45-7.45 7.45-19.53 0-26.98z"
      ></path>
    </svg>
  );
}

export default SelectArrowIcon;
