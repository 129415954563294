import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import { FND3168_REPORTS_DESIGN_FORM_ID } from "../../util/constants/forms";
import ReportsDesignTable from "./listing";
import ReportsDesignDialog from "./listing/ReportsDesignDialog";
import ActionsButton from "@ipgd-gauge/actions-button";
import { NEW_ACTION, VIEW_EDIT_ACTION } from "../../util/constants/actions";
import DownLoadAllIcon from "../../icons/DownLoadAll";
import NewWordIcon from "../../icons/NewWord";
import { downloadReports, downloadReportsSetup } from "../../util/apis";
import { ESTIMATION_PRINT_OUT } from "../../util/constants";
import Spinner from '@ipgd-gauge/spinner';
import style from './listing/style.module.scss';
import ReportsDesignFilter from './listing/Filters'
import FiltersIcon from "../../icons/Filters";

const ReportsDesign = () => {
    const { t } = useTranslation('reportsDesign');

    const searchTextRef = useRef();
    const reportsDesignTableRef = useRef();
    const reportsDesignDialogRef = useRef();
    const filterRef = useRef({});

    const getActions = () => {
        const actions = [];
        actions.push({ id: `form-action-${NEW_ACTION}`, icon: <DownLoadAllIcon fill="var(--primary-color-2)" />, label: t('reportsDesign:download_all'), action: () => downloadReports(ESTIMATION_PRINT_OUT, `form-action-${NEW_ACTION}`) },
            actions.push({ id: `form-action-${VIEW_EDIT_ACTION}`, icon: <NewWordIcon />, label: t('reportsDesign:download_word'), action: () => downloadReportsSetup(`form-action-${VIEW_EDIT_ACTION}`) })
        )
        return actions;
    }
    const _openFilter = () => {
        filterRef.current?.open();
    }

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={FND3168_REPORTS_DESIGN_FORM_ID}
                />

                <div className={`headerSearchPanel ${style.headerSearchPanel}`}>
                    <div className="frm-header-actions">
                        <button
                            id={`download-reports`}
                            className={style.actionButtons}
                            onClick={() => downloadReports(ESTIMATION_PRINT_OUT, `download-reports-spinner`)}>
                            <DownLoadAllIcon fill="var(--primary-color-2)" />
                            <Spinner
                                id='download-reports-spinner'
                                className={style.spinnerButton}
                            />
                        </button>
                        <button
                            id={`download-report-design`}
                            className={style.actionButtons}
                            onClick={() => downloadReportsSetup(`download-report-design-spinner`)}
                        >
                            <NewWordIcon />
                            <Spinner
                                id='download-report-design-spinner'
                                className={style.spinnerButton}
                            />
                        </button>
                        {/* <div>
                            <QuickSearch
                                ref={searchTextRef}
                                placeholder={t('report_name')}
                                search={() => reportsDesignTableRef?.current?.refresh()}
                                formId={FND3168_REPORTS_DESIGN_FORM_ID}
                            />
                        </div> */}
                    </div>
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('report_name')}
                        search={() => reportsDesignTableRef?.current?.refresh()}
                        formId={FND3168_REPORTS_DESIGN_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={_openFilter}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <ReportsDesignTable
                reportsDesignTableRef={reportsDesignTableRef}
                searchTextRef={searchTextRef}
                reportsDesignDialogRef={reportsDesignDialogRef}
                filterRef={filterRef}
            />

            <ReportsDesignFilter
                ref={filterRef}
                tableRef={reportsDesignTableRef}
            />

            <ReportsDesignDialog
                ref={reportsDesignDialogRef}
                reportsDesignTableRef={reportsDesignTableRef}
            />
        </>
    )
}

export default ReportsDesign;