import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker"
import FrmInput from "@ipgd-gauge/frm-input"
import { FrmSelectMenu } from "@ipgd-gauge/select-menu"
import { handleNumbersKeyDown, isEmpty } from "@ipgd-gauge/utils"
import { useTranslation } from "react-i18next"
import HijriDate from "../../../../components/hijri-date"
import { getCitiesLov, getCommonPartiesLov, getTownsList } from "../../../../util/apis"
import { PROPORTIES_PARTIES_TYPE_AGENT, PROPORTIES_PARTIES_TYPE_INTERMEDIATE, PROPORTIES_PARTIES_TYPE_INVESTOR, PROPORTIES_PARTIES_TYPE_OWNER, STATUS_ACTIVE } from "../../../../util/constants"

const ID = 'valuation-contract-contract-'
const ContractDetails = (props) => {
    const { handleDataChange, valuationContract } = props;
    const { t } = useTranslation("valuationContracts")
    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('contract_details')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label id={`${ID}code-lbl`}
                            className="form-label mandatory">
                            {t("contract_code")}
                        </label>
                        <FrmInput
                            id={`${ID}code`}
                            onBlur={(value) => handleDataChange("contractRefCode", value)}
                            value={valuationContract.contractRefCode}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${ID}date-g-lbl`} className="form-label mandatory">
                            {t("contract_date_g")}
                        </label>
                        <FrmTimeDatePickr
                            id={`${ID}date-g`}
                            onChange={(value) => handleDataChange("contractDateDgr", value)}
                            value={valuationContract.contractDateDgr}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${ID}date-h-lbl`} className="form-label mandatory">
                            {t("contract_date_h")}
                        </label>
                        <HijriDate
                            id={`${ID}date-h`}
                            onChange={(value) => handleDataChange("contractDateDhi", value)}
                            hijriDate={valuationContract?.contractDateDhi}
                            gregorianDate={valuationContract?.contractDateDgr}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${ID}period-lbl`} className="form-label mandatory">
                            {t("contract_period")}
                        </label>
                        <FrmInput
                            id={`${ID}period`}
                            onBlur={(value) => handleDataChange("contractPeriod", value)}
                            value={valuationContract.contractPeriod}
                        />
                    </div>


                    <div className="w25">
                        <label id={`${ID}period-from-lbl`} className="form-label mandatory">
                            {t("contract_period_from")}
                        </label>
                        <FrmTimeDatePickr
                            id={`${ID}period-from`}
                            onChange={(value) => handleDataChange("contFromDateDgr", value)}
                            value={valuationContract.contFromDateDgr}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${ID}period-to-lbl`} className="form-label mandatory">
                            {t("contract_period_to")}
                        </label>
                        <FrmTimeDatePickr
                            id={`${ID}period-to`}
                            onChange={(value) => handleDataChange("contToDateDgr", value)}
                            value={valuationContract.contToDateDgr}
                        />
                    </div>

                    <div className="w25">
                        <label className="form-label">
                            {t("validty_duration")}
                        </label>
                        <FrmInput
                            id={`${ID}validty-duration`}
                            onBlur={(value) => handleDataChange("validDuraution", value)}
                            value={valuationContract.validDuraution}
                        />
                    </div>


                    <div className="w25">
                        <label className="form-label">
                            {t("contract_allowed_period")}
                        </label>
                        <FrmInput
                            id={`${ID}allowed-period`}
                            onBlur={(value) => handleDataChange("contAllowedPeriod", value)}
                            value={valuationContract.contAllowedPeriod}
                        />
                    </div>



                    <div className="w25">
                        <label id={`${ID}city-lbl`} className={`form-label mandatory`}>
                            {t("city")}
                        </label>
                        <FrmSelectMenu
                            id={`${ID}city`}
                            onChange={(value) => handleDataChange("ecdId", value.value)}
                            value={valuationContract.ecdId}
                            defualtValueId={valuationContract.ecdId}
                            defualtValueApi={() => getCitiesLov(isEmpty(valuationContract.ecdId) ? [] : [valuationContract.ecdId])}
                            api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, null, searchValue)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isAsync={true}
                            portalTarget={document.body}

                        />
                    </div>

                    <div className="w25">
                        <label id={`${ID}town-lbl`} className={`form-label mandatory`}>
                            {t("town")}
                        </label>
                        <FrmSelectMenu
                            id={`${ID}town`}
                            onChange={(value) => handleDataChange("criId", value.value)}
                            value={valuationContract.criId}

                            key={`${ID}-town-${valuationContract.ecdId}`}
                            api={(searchKey) => getTownsList(searchKey, null, valuationContract.ecdId, STATUS_ACTIVE, true, null, true)}
                            defualtValueId={valuationContract.criId}
                            defualtValueApi={() => getTownsList(null, isEmpty(valuationContract.criId) ? [] : [valuationContract.criId], valuationContract.ecdId, STATUS_ACTIVE, true, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isAsync
                            isDisabled={!valuationContract.ecdId}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w25">
                        <label className="form-label">
                            {t("estimator")}
                        </label>
                        <FrmSelectMenu
                            id={`${ID}estimator`}
                            onChange={(value) => handleDataChange("aprId", value.value)}
                            value={valuationContract.aprId}
                            api={() => getCommonPartiesLov(true, [PROPORTIES_PARTIES_TYPE_OWNER, PROPORTIES_PARTIES_TYPE_AGENT, PROPORTIES_PARTIES_TYPE_INTERMEDIATE, PROPORTIES_PARTIES_TYPE_INVESTOR], false, null, isEmpty(valuationContract.aprId) ? [] : [valuationContract.aprId], STATUS_ACTIVE, null, 1)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w25">
                        <label className="form-label">
                            {t("judicial_party")}
                        </label>
                        <FrmInput
                            id={`${ID}judicial-party`}
                            onBlur={(value) => handleDataChange("judicialParty", value)}
                            value={valuationContract.judicialParty}
                        />
                    </div>


                    <div className="w75">
                        <label id={`${ID}project-name-lbl`} className={`form-label mandatory`}>
                            {t("project_name")}
                        </label>
                        <FrmInput
                            id={`${ID}project-name`}
                            onBlur={(value) => handleDataChange("projectName", value)}
                            value={valuationContract.projectName}
                        />
                    </div>

                    <div className="w25">
                        <label className="form-label">
                            {t("num_work_days")}
                        </label>
                        <FrmInput
                            id={`${ID}work-days`}
                            value={valuationContract.noOfDays}
                            onBlur={(value) => handleDataChange("noOfDays", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className="form-label">
                            {t("desc")}
                        </label>
                        <FrmInput
                            id={`${ID}desc`}
                            onBlur={(value) => handleDataChange("contractDescription", value)}
                            value={valuationContract.contractDescription}
                        />
                    </div>

                    <div className="w100">
                        <label className="form-label">
                            {t("details")}
                        </label>
                        <FrmInput
                            id={`${ID}details`}
                            onBlur={(value) => handleDataChange("contractDetails", value)}
                            value={valuationContract.contractDetails}
                        />
                    </div>

                    <div className="w100">
                        <label className="form-label">
                            {t("payment_desc")}
                        </label>
                        <FrmInput
                            id={`${ID}payment-desc`}
                            onBlur={(value) => handleDataChange("paymentDesc", value)}
                            value={valuationContract.paymentDesc}
                        />
                    </div>


                    <div className="w50">
                        <label className="form-label">
                            {t("report_type")}
                        </label>
                        <FrmInput
                            id={`${ID}report-type`}
                            onBlur={(value) => handleDataChange("reportType", value)}
                            value={valuationContract.reportType}

                        />
                    </div>
                    <div className="w50">
                        <label className="form-label">
                            {t("report_delivery")}
                        </label>
                        <FrmInput
                            id={`${ID}reporty-delivery`}
                            onBlur={(value) => handleDataChange("repDeliveryMethod", value)}
                            value={valuationContract.repDeliveryMethod}

                        />
                    </div>

                    <div className="w100">
                        <label className="form-label">
                            {t("report_users")}
                        </label>
                        <FrmInput
                            id={`${ID}report-users`}
                            onBlur={(value) => handleDataChange("reportUsers", value)}
                            value={valuationContract.reportUsers}
                        />
                    </div>
                    <div className="w100">
                        <label className="form-label">
                            {t("documents_source")}
                        </label>
                        <FrmInput
                            id={`${ID}documents-source`}
                            onBlur={(value) => handleDataChange("documentsSource", value)}
                            value={valuationContract.documentsSource}

                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContractDetails;