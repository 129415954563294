import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, isAllowedAction, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import EditTableIcon from "../../../icons/EditTable";
import RejectIcon from "../../../icons/Reject";
import ViewIcon from "../../../icons/View";
import { confirmCreditNoteCustomerEstimation, deleteCreditNotesCustomersEstimation, rejectCreditNoteCustomerEstimation, unpostCreditNoteCustomerEstimation } from "../../../util/apis";
import { GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, PRINT_ACTION, REJECT_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import InputsBtnVeiwIcon from "../../../icons/InputsBtnVeiw";

const Actions = (props) => {
    const { user, data, tableRef, printDialogRef, zatcaSummaryRef, isLinkWithZatca } = props
    const { t } = useTranslation();
    const [actions, setActions] = useState([]);
    const navigate = useNavigate();

    const openZatcaSummary = () => {
        zatcaSummaryRef.current.open(RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, data.id);
    }

    const _deleteCreditNote = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteCreditNotesCustomersEstimation(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
        });
    }

    const _rejectCreditNote = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectCreditNoteCustomerEstimation(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
        });
    }

    const _confirmCreditNote = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return confirmCreditNoteCustomerEstimation(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
        });
    }

    const _unpostCreditNote = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return unpostCreditNoteCustomerEstimation(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
        });
    }

    const _isAllowedLinkZatca = () => {
        return isLinkWithZatca() && data?.globalStatus == GLOBAL_STATUS_CONFIRMED;
    }

    const _getActions = () => {
        let actions = [];

        if (isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, VIEW_EDIT_ACTION)) {
            if ((data.globalStatus == GLOBAL_STATUS_REJECTED || data.globalStatus == GLOBAL_STATUS_CONFIRMED)) {
                actions.push({
                    label: t('view'),
                    action: () => navigate(`/credit-notes-for-customer-estimation?id=${data.id}`),
                    icon: <ViewIcon />
                });
            } else {
                actions.push({
                    label: t('edit'),
                    action: () => navigate(`/credit-notes-for-customer-estimation?id=${data.id}`),
                    icon: <EditTableIcon />
                });
            }
        }

        if (data.globalStatus == GLOBAL_STATUS_INCOMPLETE && isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, DELETE_ACTION)) {
            actions.push({
                label: t('delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action: _deleteCreditNote,
                icon: <DeleteTowIcon />
            });
        }

        if (data.globalStatus == GLOBAL_STATUS_SUBMITTED) {
            if (isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, REJECT_ACTION)) {
                actions.push(
                    {
                        label: t('creditNotesForCustomersEstimation:reject'),
                        color: "var(--action-delete-bg)",
                        action: _rejectCreditNote,
                        icon: <RejectIcon />
                    }
                );
            }

            if (isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, CONFIRM_FORM_ACTION)) {
                actions.push({
                    label: t('creditNotesForCustomersEstimation:confirm'),
                    action: _confirmCreditNote,
                    icon: <ApproveIcon />
                });
            }
        }

        if (isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, PRINT_ACTION)) {
            actions.push(
                {
                    label: t('print'),
                    action: () => printDialogRef?.current.open([data.id]),
                    icon: <ViewIcon />,
                }
            )
        }

        if(_isAllowedLinkZatca()){
            actions.push({
                label: t('zatca_summary'),
                action: openZatcaSummary,
                icon: <InputsBtnVeiwIcon />,
                color: "#007A3D",
            })
        }

        // if (data.globalStatus == GLOBAL_STATUS_CONFIRMED && isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, UN_POST_ACTION)) {
        //     actions.push({
        //         label: t('customersEstimationInvoices:unpost'),
        //         color: "var(--action-unpost)",
        //         action: _unpostCreditNote,
        //         icon: <UnpostIcon />
        //     });
        // }

        setActions(actions);
    }

    useEffect(() => {
        _getActions();
    }, [])

    return (<TableActionsButton defaultIndex={0} buttons={actions} />)
}

export default Actions;