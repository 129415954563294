import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Map from '../../../../components/map';
import MapPinIcon from '../../../../icons/MapPin';
import { LIMIT_LAT, LIMIT_LNG, TRX_DISPLAY_MODE_1 } from '../../../../util/constants';
import style from './style.module.scss';

const TrxMap = ({ handleChange, displayMode, getTrxKey }) => {
    const trnxValues = useSelector(state => state.estTrnxReducer.data);
    const mapRef = useRef(null);
    const disableTimes = useRef(0);
    const { t } = useTranslation('estimationTransaction');

    const [coords, setCoords] = useState({});

    useEffect(() => {
        if (disableTimes.current == 0) {
            setCoords({ 'TRI_LONGITUDE': trnxValues.TRI_LONGITUDE, 'TRI_LATITUDE': trnxValues.TRI_LATITUDE, 'TRI_MAP_ZOOM': trnxValues.TRI_MAP_ZOOM })
        }
        if (disableTimes.current > 0) {
            disableTimes.current = disableTimes.current - 1;
        }
    }, [trnxValues.TRI_LONGITUDE, trnxValues.TRI_LATITUDE, trnxValues.TRI_MAP_ZOOM])

    useEffect(() => {
        if (coords.TRI_LONGITUDE && coords.TRI_LATITUDE && mapRef?.current) {
            mapRef.current.changePinLocation(coords.TRI_LATITUDE, coords.TRI_LONGITUDE, coords.TRI_MAP_ZOOM)
        }
    }, [coords.TRI_LONGITUDE, coords.TRI_LATITUDE, coords.TRI_MAP_ZOOM])


    const _changeLatlng = (lat, lng) => {
        if (Math.abs(lat) > LIMIT_LAT) {
            lat = trnxValues.TRI_LATITUDE;
        }

        if (Math.abs(lng) > LIMIT_LNG) {
            lng = trnxValues.TRI_LONGITUDE;
        }

        if (lat != coords['TRI_LATITUDE'] || lng != coords['TRI_LONGITUDE']) {
            coords.TRI_LATITUDE = lat
            coords.TRI_LONGITUDE = lng
            setCoords({ ...coords })
            disableTimes.current = 2
            handleChange('TRI_LATITUDE', lat)
            handleChange('TRI_LONGITUDE', lng)
        }
    }

    const _changeZoom = zoom => {
        if (zoom != coords.TRI_MAP_ZOOM) {
            // coords.TRI_MAP_ZOOM = zoom
            // setCoords({ ...coords })
            disableTimes.current = 1
            handleChange('TRI_MAP_ZOOM', zoom)
        }
    }

    const _goToCurrentLocation = () => {
        const options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };
        navigator.geolocation.getCurrentPosition((pos) => {
            const crd = pos.coords;
            _changeLatlng(crd.latitude, crd.longitude)
        }, () => { }, options);
    }

    const _openCompareMap = () => {
        window.open(`/estimation-map?comp=${trnxValues.TRI_ID || -1}&k=${getTrxKey()}`, '_blank')
    }

    return (
        <div className={`row`}>
            <div className={`w100 ${style.mapBtns} ${displayMode == TRX_DISPLAY_MODE_1 ? 'mode1' : 'mode2'}`}>
                <button className='secondary-button' onClick={_goToCurrentLocation} disabled={window.location.protocol != "https:"} >{t('current_location')}</button>
                <button className='secondary-button' onClick={_openCompareMap}>{t('compare')}</button>
            </div>
            <div className='w100'>
                <div className={style.mapContainer}>
                    <Map
                        withSearch
                        key={`map-key-${trnxValues.TRI_ID}`}
                        ref={mapRef}
                        value={coords.TRI_LONGITUDE && coords.TRI_LATITUDE ? `${coords.TRI_LATITUDE},${coords.TRI_LONGITUDE}` : null}
                        withPin={true}
                        zoom={Number(trnxValues.TRI_MAP_ZOOM)}
                        MapPinCard={<MapPinIcon />}
                        handleZoomChange={zoom => _changeZoom(zoom)}
                        handleChange={(e) => _changeLatlng(e.lat, e.lng)}
                        disableChangeOnMove
                        hybridMap={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default TrxMap;