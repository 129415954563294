import React from "react";

function NewTabIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 13.05 13.05"
    >
      <path
        fill="#fff"
        d="M12.59 0H9.68c-.25 0-.46.2-.46.46s.2.46.46.46h1.81L6.81 5.6c-.18.18-.18.47 0 .64.09.09.21.13.32.13s.23-.04.32-.13l4.69-4.69v1.81c0 .25.2.46.46.46s.46-.2.46-.46V.46c0-.25-.2-.46-.46-.46Z"
      ></path>
      <path
        fill="#fff"
        d="M12.59 6.67c-.25 0-.46.2-.46.46v1.21c0 2.8-.99 3.79-3.79 3.79H4.7c-2.8 0-3.79-.99-3.79-3.79V4.7C.91 1.9 1.9.91 4.7.91h1.21c.25 0 .46-.2.46-.46s-.2-.46-.46-.46H4.7C1.41 0 0 1.41 0 4.7v3.64c0 3.3 1.41 4.7 4.7 4.7h3.64c3.3 0 4.7-1.41 4.7-4.7V7.13c0-.25-.2-.46-.46-.46Z"
      ></path>
    </svg>
  );
}

export default NewTabIcon;
