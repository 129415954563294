import { deleteUrlParam, getUrlParam, isNotEmpty, removeAllMandatoryClasses } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import { getCreditNoteCustomerEstimation, getDefaultFormValues } from "../../../util/apis";
import { DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Actions from "./Actions";
import BranchDivision from "./BranchDivision";
import Customer from "./Customer";
import Details from "./Details";
import Header from "./Header";
import Invoice from "./Invoice";
import creditNoteReducer, { ACTION_CREATE_NEW_CREADIT_NOTE, ACTION_SET_CREADIT_NOTE } from "./reducer";
import style from './style.module.scss';
import Summary from "./summary";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import ZatcaSummaryDialog from "../../common/dialogs/zatca-summary-dialog";

const CreditNotesForCustomerEstimation = () => {

    const id = getUrlParam('id');
    const paramCustomerId = getUrlParam('atrId');
    const { t } = useTranslation();
    const user = getUser();

    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);
    const printDialogRef = useRef();
    const zatcaSummaryRef = useRef();


    const initialState = {
        creditNote: {
            header: {
                globalStatus: GLOBAL_STATUS_INCOMPLETE,
                exchngRate: 1,
                fogId: user?.fogId,
                accountType: DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN,
                lsoId: user?.estimationInvoiceLsoId,
                mbnId: user?.mbnId,
                dsvId: user?.dsvId,
            },
            lines: [],
        },
        totals: {},
    }

    const [creditNoteState, dispatchCreditNote] = useReducer(creditNoteReducer, initialState);

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (id) {
            getCreditNoteCustomerEstimation(id)
                .then((response) => {
                    // response.data.lines.map((line) => {
                    //     if (line.source == ESTIMATION_FEES) {
                    //         console.log("🚀 ~ response.data.lines.map ~ line:", line)
                    //         line.applicationNum = line?.applicationNum
                    //     } else if (line.source == ITEM) {
                    //         line.descLo = line?.item?.code + ', ' + line?.item?.descLo
                    //         line.descFo = line?.item?.code + ', ' + line?.item?.descFo
                    //     }
                    // })
                    let invoiceData = { header: response.data, lines: response.data.lines };
                    dispatchCreditNote({ value: invoiceData, type: ACTION_SET_CREADIT_NOTE })
                })
        } else {
            _newInvoice();
        }
    }, [id]);

    const _newInvoice = async () => {
        let newCreaditNote = { ...initialState };
        const defaultData = (await getDefaultFormValues(RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID)).data
        if (isNotEmpty(paramCustomerId)) {
            newCreaditNote.creditNote.header.atrId = paramCustomerId;
            newCreaditNote.creditNote.header.accountType = DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN;
        }
        newCreaditNote.creditNote.header.dateDgr = defaultData.currentDate;
        newCreaditNote.creditNote.header.fcuId = defaultData?.fcuId;
        fromNewActionRef.current = true;
        deleteUrlParam("id");
        dispatchCreditNote({ value: newCreaditNote, type: ACTION_CREATE_NEW_CREADIT_NOTE })
        removeAllMandatoryClasses(document.getElementById("credit-notes-for-customer-estimation"))
    }

    const handleOpenSummary = () => {
        document.getElementById('credit-notes-for-customer-estimation-lines').classList.toggle('active');
        document.getElementById('credit-notes-for-customer-estimation-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }


    return (
        <>
            <div className={style.container}>
                <div id="credit-notes-for-customer-estimation-lines" className="lines-container-open-close">
                    <div id='credit-notes-for-customer-estimation' className={`toggle-mode ${style.toggleMode}`}>
                        <Header
                            creditNote={creditNoteState.creditNote}
                            dispatchCreditNote={dispatchCreditNote}
                        />
                        <BranchDivision
                            user={user}
                            creditNote={creditNoteState.creditNote}
                            dispatchCreditNote={dispatchCreditNote}
                        />
                        <Customer
                            creditNote={creditNoteState.creditNote}
                            dispatchCreditNote={dispatchCreditNote}
                        />
                        <Invoice
                            user={user}
                            creditNote={creditNoteState.creditNote}
                            dispatchCreditNote={dispatchCreditNote}
                        />
                        <Details
                            user={user}
                            creditNote={creditNoteState.creditNote}
                            dispatchCreditNote={dispatchCreditNote}
                        />
                    </div>
                </div>

                <div id="credit-notes-for-customer-estimation-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        dispatchCreditNote={dispatchCreditNote}
                        creditNote={creditNoteState.creditNote}
                        totals={creditNoteState.totals}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                user={user}
                creditNoteState={creditNoteState}
                dispatchCreditNote={dispatchCreditNote}
                newAction={_newInvoice}
                fromSaveActionRef={fromSaveActionRef}
                printDialogRef={printDialogRef}
                zatcaSummaryRef={zatcaSummaryRef}
            />
            <ZatcaSummaryDialog
                ref={zatcaSummaryRef}
            />
            <PrintDialog
                ref={printDialogRef}
                id="credit-notes-for-customer-estimation-print-dialog"
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                user={user}
            />
        </>
    )
}

export default CreditNotesForCustomerEstimation;