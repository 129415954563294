import { deleteUrlParam, getUrlParam, isEmpty, isNotEmpty, removeAllMandatoryClasses, showNotificationToast } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import { getDefaultFormValues, getEstimationInvoice, getLinkWithZatcaPref } from "../../../util/apis";
import { DATE_LEVEL_DOMAIN_ALL, DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN, ERP_FORM_ID_CUSTOMERS_ESTIMATION_INVOICES, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import ZatcaSummaryDialog from "../../common/dialogs/zatca-summary-dialog";
import Actions from "./Actions";
import Details, { ESTIMATION_FEES, ITEM } from "./Details";
import Header from "./Header";
import invoiceReducer, { ACTION_CREATE_NEW_INVOICE, ACTION_SET_INVOICE } from "./reducer";
import style from './style.module.scss';
import Summary from "./summary";

const CustomersEstimationInvoice = () => {

    const invoiceId = getUrlParam('id');
    const paramCustomerId = getUrlParam('atrId');
    const user = getUser();
    const fromSaveActionRef = useRef(false);
    const zatcaSummaryRef = useRef();

    const initialState = {
        invoice: {
            header: {
                globalStatus: GLOBAL_STATUS_INCOMPLETE,
                exchngRate: 1,
                fogId: user?.fogId,
                accountType: DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN,
                lsoId: user?.estimationInvoiceLsoId,
                mbnId: user?.mbnId,
                dsvId: user?.dsvId,
                billingDateMode: DATE_LEVEL_DOMAIN_ALL
            },
            lines: [],
            filteredLines: null
        },
        totals: {},
    }

    const { t } = useTranslation();
    const printDialogRef = useRef();

    const [invoiceState, dispatchInvoice] = useReducer(invoiceReducer, initialState);

    useEffect(() => {
        if (invoiceId && !fromSaveActionRef.current) {
            getEstimationInvoice(invoiceId)
                .then((response) => {
                    response.data.lines.map((line) => {
                        if (line.source == ESTIMATION_FEES) {
                            line.applicationNum = line?.transaction?.code
                        } else if (line.source == ITEM) {
                            line.descLo = line?.item?.code + ', ' + line?.item?.descLo
                            line.descFo = line?.item?.code + ', ' + line?.item?.descFo
                        }
                    })
                    let invoiceData = { header: response.data, lines: response.data.lines };
                    dispatchInvoice({ value: invoiceData, type: ACTION_SET_INVOICE })
                })
        } else if (!fromSaveActionRef.current) {
            _newInvoice();
        }
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
        }
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
        }
    }, [invoiceId]);

    const _newInvoice = async () => {
        let newInvoice = { ...initialState };
        const defaultData = (await getDefaultFormValues(RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID)).data
        if (isNotEmpty(paramCustomerId)) {
            newInvoice.invoice.header.atrId = paramCustomerId;
            newInvoice.invoice.header.accountType = DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN;
        }
        newInvoice.invoice.header.dateDgr = defaultData.currentDate;
        newInvoice.invoice.header.fcuId = defaultData?.fcuId;
        if (isEmpty(defaultData?.estimationInvoicePolicyId)) {
            showNotificationToast(t('USER_POLICY_ERR'), { hideProgressBar: true, type: 'warning' })
        }
        dispatchInvoice({ value: newInvoice, type: ACTION_CREATE_NEW_INVOICE })
        removeAllMandatoryClasses(document.getElementById("customers-estimation-container"))
        deleteUrlParam("id");
    }

    const handleOpenSummary = () => {
        document.getElementById('customers-estimation-invoices-lines').classList.toggle('active');
        document.getElementById('customers-estimation-invoices-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    const isLinkWithZatca = async () => {
        const resp = await getLinkWithZatcaPref();
        return resp.data == '100001';
    }

    return (
        <>
            <div className={style.container}>
                <div id="customers-estimation-invoices-lines" className="lines-container-open-close">
                    <div>
                        <div id='customers-estimation-container' className={`toggle-mode ${style.toggleMode}`}>
                            <Header
                                user={user}
                                invoice={invoiceState.invoice}
                                dispatchInvoice={dispatchInvoice}
                            />
                            <Details
                                user={user}
                                invoice={invoiceState.invoice}
                                dispatchInvoice={dispatchInvoice}
                            />
                        </div>
                    </div>
                </div>
                <div id="customers-estimation-invoices-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        dispatchInvoice={dispatchInvoice}
                        invoice={invoiceState.invoice}
                        totals={invoiceState.totals}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                user={user}
                invoiceState={invoiceState}
                dispatchInvoice={dispatchInvoice}
                printDialogRef={printDialogRef}
                newAction={_newInvoice}
                fromSaveActionRef={fromSaveActionRef}
                zatcaSummaryRef={zatcaSummaryRef}
            />
            <ZatcaSummaryDialog
                ref={zatcaSummaryRef}
            />
            <PrintDialog
                id="customer-estimation-invoice-print-dialog"
                ref={printDialogRef}
                formId={ERP_FORM_ID_CUSTOMERS_ESTIMATION_INVOICES}
                user={user}
            />
        </>
    )
}

export default CustomersEstimationInvoice;