import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modal";
import style from './style.module.scss';
import FrmInput from "@ipgd-gauge/frm-input";
import FrmTextArea from "@ipgd-gauge/frm-text-area";
import { exportZatcaXml, getZatcasummary } from "../../../../util/apis";
import { downloadXml } from "../../../../util/util";
import { FrmDecimalInput } from "@ipgd-gauge/decimal-input";

const ID = 'zatca-summary-dialog';

const ZatcaSummaryDialog = (props, ref) => {
    const { t } = useTranslation();

    const zatcaSummaryRef = useRef();
    const trxIdRef = useRef();
    const formIdRef = useRef();

    const [data, setData] = useState(null);

    const open = (formId, trxId) => {
        zatcaSummaryRef?.current?.open();
        trxIdRef.current = trxId;
        formIdRef.current = formId;
        getZatcasummary(formId, trxId).then(res => {
            setData(res.data);
        })
    }

    const close = () => {
        zatcaSummaryRef?.current?.close();
    }

    const _exportZatcaXml = () => {
        exportZatcaXml(formIdRef.current, trxIdRef.current).then(response => {
            downloadXml(response);
        })
    }
    useImperativeHandle(ref, () => ({
        open,
        close
    }));



    return (
        <Modal
            id={ID}
            ref={zatcaSummaryRef}
            title={t('zatca_summary')}
            withFooter={true}
            withOkButton={false}
            withCancelButton={true}
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.zatcaSummaryDialog}
            postClose={() => {
                setData({});
            }}
            smallSize
        >
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w33">
                        <label className='form-label'>{t('code')}</label>
                        <FrmInput
                            id={`${ID}-code`}
                            value={data?.code}
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_price')}</label>
                        <FrmDecimalInput
                            id={`${ID}-total-price`}
                            value={data?.linesTotal}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <button className={` ${style.downloadBtn} primary-button`} onClick={_exportZatcaXml}>{t('download_xml')}</button>
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_discounts_s')}</label>
                        <FrmDecimalInput
                            id={`${ID}-discounts-s`}
                            value={data?.discountsTotalSText}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_discounts_z')}</label>
                        <FrmDecimalInput
                            id={`${ID}-discounts-z`}
                            value={data?.discountsTotalZText}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_discounts_o')}</label>
                        <FrmDecimalInput
                            id={`${ID}-discounts-o`}
                            value={data?.discountsTotalOText}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_addons_s')}</label>
                        <FrmDecimalInput
                            id={`${ID}-addons-s`}
                            value={data?.addonsTotalSText}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_addons_z')}</label>
                        <FrmDecimalInput
                            id={`${ID}-addons-z`}
                            value={data?.addonsTotalZText}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('total_addons_o')}</label>
                        <FrmDecimalInput
                            id={`${ID}-addons-o`}
                            value={data?.addonsTotalOText}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('vat_amount_lc')}</label>
                        <FrmDecimalInput
                            id={`${ID}-vat-amount-lc`}
                            value={data?.taxSar}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('vat_amount_fc')}</label>
                        <FrmDecimalInput
                            id={`${ID}-vat-amount-fc`}
                            value={data?.taxForeign}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('net_price')}</label>
                        <FrmDecimalInput
                            id={`${ID}-net`}
                            value={data?.net}
                            decimalScale={2}
                            allowDecimals
                            readOnly
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('exemption_reason')}</label>
                        <FrmTextArea
                            id={`${ID}-exemption-reason`}
                            value={data?.reasons}
                            readOnly
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('tax_sync_result')}</label>
                        <FrmTextArea
                            key={data?.id}
                            id={`${ID}-tax-sync-result`}
                            value={data?.response}
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(ZatcaSummaryDialog);