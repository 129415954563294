import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Checkbox from "./Checkbox";
import InputText from "./InputText";
import SelectMenu from "./SelectMenu";
import style from './style.module.scss';
import InputNumber from "./InputNumber";

const GeneralEstimationBlock = (props) => {
    const { block, handleChange, getTrxKey } = props;

    const [feilds, setFeilds] = useState([]);
    const generalBlockFeilds = useSelector(state => state.estTrnxReducer.blocks[block.id]);

    useEffect(() => {
        if (generalBlockFeilds) {
            setFeilds(generalBlockFeilds);
        }
    }, [generalBlockFeilds])

    const getFeild = (name) => {
        return feilds.find(({ internalName }) => internalName == name);
    }

    return (
        <div id={`block-content-${block.id}`} className={`form-templates-panel block-content ${style.formTemplates}`}>
            <div className={`row ${style.row}`}>
                <div className="w50">
                    {(getFeild("MTA_VISION_INSP_RESULT1")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT1_REM")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT1_REC")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT2")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT2_REM")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT2_REC")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT3")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT3_REM")?.id ||
                        getFeild("MTA_VISION_INSP_RESULT3_REC")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow}`}>
                                {getFeild("MTA_VISION_INSP_RESULT1")?.id &&
                                    <div className="w100">
                                        <Checkbox
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT1")}
                                        />
                                    </div>}

                                {getFeild("MTA_VISION_INSP_RESULT1_REM")?.id &&
                                    <div className="w100">
                                        <InputText multiLines
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT1_REM")} />
                                    </div>}

                                {getFeild("MTA_VISION_INSP_RESULT1_REC")?.id &&
                                    <div className="w100">
                                        <InputText multiLines
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT1_REC")} />
                                    </div>
                                }

                                {getFeild("MTA_VISION_INSP_RESULT2")?.id &&
                                    <div className="w100">
                                        <Checkbox
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT2")}
                                        />
                                    </div>}

                                {getFeild("MTA_VISION_INSP_RESULT2_REM")?.id &&
                                    <div className="w100">
                                        <InputText multiLines
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT2_REM")} />
                                    </div>}

                                {getFeild("MTA_VISION_INSP_RESULT2_REC")?.id &&
                                    <div className="w100">
                                        <InputText multiLines
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT2_REC")} />
                                    </div>
                                }

                                {getFeild("MTA_VISION_INSP_RESULT3")?.id &&
                                    <div className="w100">
                                        <Checkbox
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT3")}
                                        />
                                    </div>}

                                {getFeild("MTA_VISION_INSP_RESULT3_REM")?.id &&
                                    <div className="w100">
                                        <InputText multiLines
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT3_REM")} />
                                    </div>}

                                {getFeild("MTA_VISION_INSP_RESULT3_REC")?.id &&
                                    <div className="w100">
                                        <InputText multiLines
                                            handleChange={handleChange}
                                            feild={getFeild("MTA_VISION_INSP_RESULT3_REC")} />
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {(getFeild("MTA_SHOWROOMS_ESTIMATION")?.id ||
                        getFeild("MTA_OFFICES_ESTIMATION")?.id ||
                        getFeild("MTA_APARTMENTS_ESTIMATION")?.id ||
                        getFeild("MTA_OTHER_PROP_ESTIMATION")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_SHOWROOMS_ESTIMATION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_SHOWROOMS_ESTIMATION")} />
                                </div>}

                                {getFeild("MTA_OFFICES_ESTIMATION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_OFFICES_ESTIMATION")} />
                                </div>}

                                {getFeild("MTA_APARTMENTS_ESTIMATION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_APARTMENTS_ESTIMATION")} />
                                </div>}

                                {getFeild("MTA_OTHER_PROP_ESTIMATION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_OTHER_PROP_ESTIMATION")} />
                                </div>}

                            </div>
                        </div>
                    }

                    {(getFeild("MTA_NEEDS_MAINTAINANCE")?.id ||
                        getFeild("MTA_MAINTINANCE_DESCRIPTION")?.id ||
                        getFeild("MTA_COST_OF_MAINTINANCE")?.id ||
                        getFeild("MTA_REMAINING_MAIN_PERC")?.id ||
                        getFeild("MTA_COMPLETED_MAIN_PERC")?.id) &&
                        <div className={style.generalEstPanel}>
                            {/* label={t('asset_needs_maintenance')} */}
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_NEEDS_MAINTAINANCE")?.id && <div className="w50">
                                    <Checkbox
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_NEEDS_MAINTAINANCE")}
                                    />
                                </div>}

                                {getFeild("MTA_MAINTINANCE_DESCRIPTION")?.id && <div className="w50">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_MAINTINANCE_DESCRIPTION")} />
                                </div>}

                                {getFeild("MTA_COST_OF_MAINTINANCE")?.id && <div className="w100">
                                    <InputNumber
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_COST_OF_MAINTINANCE")} />
                                </div>}

                                {getFeild("MTA_REMAINING_MAIN_PERC")?.id && <div className="w50">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_REMAINING_MAIN_PERC")} />
                                </div>}

                                {getFeild("MTA_COMPLETED_MAIN_PERC")?.id && <div className="w50">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_COMPLETED_MAIN_PERC")} />
                                </div>}

                            </div>
                        </div>
                    }

                    {(getFeild("MTA_ADVANTAGES_1")?.id || getFeild("MTA_ADVANTAGES_2")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_ADVANTAGES_1")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_ADVANTAGES_1")} />
                                </div>}

                                {getFeild("MTA_ADVANTAGES_2")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ADVANTAGES_2")} />
                                </div>}
                            </div>
                        </div>
                    }

                    {(getFeild("MTA_DISADVANTAGES_1")?.id || getFeild("MTA_DISADVANTAGES_2")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_DISADVANTAGES_1")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_DISADVANTAGES_1")} />
                                </div>}

                                {getFeild("MTA_DISADVANTAGES_2")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_DISADVANTAGES_2")} />
                                </div>}
                            </div>
                        </div>
                    }

                    {(getFeild("MTA_OWNERSHIP_TYPE2")?.id ||
                        getFeild("MTA_OWNERSHIP_TYPE")?.id ||
                        getFeild("MTA_RIGHTS_OF_OTHERS_TYPE")?.id ||
                        getFeild("MTA_OTHERS_RIGHTS")?.id ||
                        getFeild("MTA_CONSTUCTION_SYSTEM_PERC")?.id ||
                        getFeild("MTA_ALLOWED_FLOORS_SYSTEM")?.id ||
                        getFeild("MTA_CONFORM_TO_CONST_PERMIT")?.id ||
                        getFeild("MTA_CONST_PERMIT_CONTRARY_DESC")?.id ||
                        getFeild("MTA_IS_CONTRARY_TO_CONST_RULES")?.id ||
                        getFeild("MTA_CONST_RULES_CONTRARY_DESC")?.id ||
                        getFeild("MTA_ALL_ROOFS_ARE_CONCRETE")?.id ||
                        getFeild("MTA_EXEPT_CONCRETE_ROOFS_DESC")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_OWNERSHIP_TYPE2")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_OWNERSHIP_TYPE2")} />
                                </div>}
                                {getFeild("MTA_OWNERSHIP_TYPE")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_OWNERSHIP_TYPE")} />
                                </div>}
                                {getFeild("MTA_RIGHTS_OF_OTHERS_TYPE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_RIGHTS_OF_OTHERS_TYPE")} />
                                </div>}
                                {getFeild("MTA_OTHERS_RIGHTS")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_OTHERS_RIGHTS")} />
                                </div>}
                                {getFeild("MTA_CONFORM_TO_CONST_PERMIT")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_CONFORM_TO_CONST_PERMIT")} />
                                </div>}
                                {getFeild("MTA_CONST_PERMIT_CONTRARY_DESC")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_CONST_PERMIT_CONTRARY_DESC")} />
                                </div>}
                                {getFeild("MTA_IS_CONTRARY_TO_CONST_RULES")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_IS_CONTRARY_TO_CONST_RULES")} />
                                </div>}
                                {getFeild("MTA_CONST_RULES_CONTRARY_DESC")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_CONST_RULES_CONTRARY_DESC")} />
                                </div>}
                                {getFeild("MTA_CONSTUCTION_SYSTEM_PERC")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_CONSTUCTION_SYSTEM_PERC")} />
                                </div>}

                                {getFeild("MTA_ALLOWED_FLOORS_SYSTEM")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ALLOWED_FLOORS_SYSTEM")} />
                                </div>}
                                {getFeild("MTA_ALL_ROOFS_ARE_CONCRETE")?.id && <div className="w100">
                                    <Checkbox
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ALL_ROOFS_ARE_CONCRETE")}
                                    />
                                </div>}
                                {getFeild("MTA_EXEPT_CONCRETE_ROOFS_DESC")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_EXEPT_CONCRETE_ROOFS_DESC")} />
                                </div>}
                            </div>
                        </div>
                    }

                    {(getFeild("MTA_DEMANDS_1")?.id || getFeild("MTA_DEMANDS_2")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_DEMANDS_1")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_DEMANDS_1")} />
                                </div>}

                                {getFeild("MTA_DEMANDS_2")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_DEMANDS_2")} />
                                </div>}
                            </div>
                        </div>
                    }
                </div>

                <div className="w50">
                    {(getFeild("MTA_VISION_INSP_RESULT4_REM")?.id || getFeild("MTA_VISION_INSP_RESULT4_REC")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_VISION_INSP_RESULT4_REM")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_VISION_INSP_RESULT4_REM")} />
                                </div>}

                                {getFeild("MTA_VISION_INSP_RESULT4_REC")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_VISION_INSP_RESULT4_REC")} />
                                </div>}
                            </div>
                        </div>
                    }

                    {(getFeild("MTA_FAULTS_REMARKS_1")?.id ||
                        getFeild("MTA_FAULTS_REMARKS_2")?.id ||
                        getFeild("MTA_FAULTS_REMARKS_3")?.id ||
                        getFeild("MTA_FAULTS_REMARKS_4")?.id ||
                        getFeild("MTA_FAULTS_REMARKS_5")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_FAULTS_REMARKS_1")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_FAULTS_REMARKS_1")} />
                                </div>}

                                {getFeild("MTA_FAULTS_REMARKS_2")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_FAULTS_REMARKS_2")} />
                                </div>}

                                {getFeild("MTA_FAULTS_REMARKS_3")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_FAULTS_REMARKS_3")} />
                                </div>}

                                {getFeild("MTA_FAULTS_REMARKS_4")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_FAULTS_REMARKS_4")} />
                                </div>}

                                {getFeild("MTA_FAULTS_REMARKS_5")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_FAULTS_REMARKS_5")} />
                                </div>}
                            </div>
                        </div>}

                    {(getFeild("MTA_TOWN_PROP_PRICE")?.id ||
                        getFeild("MTA_PROPERTY_INVESTMENT")?.id ||
                        getFeild("MTA_RECOMMENDATION")?.id ||
                        getFeild("MTA_DECISION")?.id ||
                        getFeild("MTA_PROPERTY_ACCEPTANCE")?.id ||
                        getFeild("MTA_ESTIMATOR_OPENION")?.id ||
                        getFeild("MTA_INSPECTOR_OPENION")?.id ||
                        getFeild("MTA_PRIVATE_REMARKS")?.id ||
                        getFeild("MTA_PROPERTY_DESCRIPTION")?.id ||
                        getFeild("MTA_PROPERTY_INSPECTION")?.id ||
                        getFeild("MTA_MARKET_DESCRIPTION")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_TOWN_PROP_PRICE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_TOWN_PROP_PRICE")} />
                                </div>}
                                {getFeild("MTA_PROPERTY_INVESTMENT")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_PROPERTY_INVESTMENT")} />
                                </div>}
                                {getFeild("MTA_RECOMMENDATION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_RECOMMENDATION")} />
                                </div>}
                                {getFeild("MTA_DECISION")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_DECISION")} />
                                </div>}
                                {getFeild("MTA_PROPERTY_ACCEPTANCE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_PROPERTY_ACCEPTANCE")} />
                                </div>}
                                {getFeild("MTA_ESTIMATOR_OPENION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ESTIMATOR_OPENION")} />
                                </div>}
                                {getFeild("MTA_INSPECTOR_OPENION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_INSPECTOR_OPENION")} />
                                </div>}
                                {getFeild("MTA_PRIVATE_REMARKS")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_PRIVATE_REMARKS")} />
                                </div>}
                                {getFeild("MTA_PROPERTY_DESCRIPTION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_PROPERTY_DESCRIPTION")} />
                                </div>}
                                {getFeild("MTA_PROPERTY_INSPECTION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_PROPERTY_INSPECTION")} />
                                </div>}
                                {getFeild("MTA_MARKET_DESCRIPTION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_MARKET_DESCRIPTION")} />
                                </div>}
                            </div>
                        </div>}

                    {(getFeild("MTA_ESTIMATION_SCOPE")?.id ||
                        getFeild("MTA_ESTIMATION_REASON")?.id ||
                        getFeild("MTA_ESTIMATION_REASON_TEXT")?.id ||
                        getFeild("MTA_ESTIMATION_BASE")?.id ||
                        getFeild("MTA_ESTIMATION_BASE_DESC")?.id ||
                        getFeild("MTA_ESTIMATION_METHOD")?.id ||
                        getFeild("MTA_ESTIMATION_METHOD_MULTI")?.id ||
                        getFeild("MTA_ESTIMATION_STYLE")?.id ||
                        getFeild("MTA_ESTIMATION_METHOD_DESC")?.id ||
                        getFeild("MTA_VALUE_ASSUMPTION")?.id ||
                        getFeild("MTA_RESEARCH_SCOPE")?.id ||
                        getFeild("MTA_REPORT_USAGE")?.id ||
                        getFeild("MTA_DATA_SOURCE")?.id ||
                        getFeild("MTA_COPYRIGHTS")?.id ||
                        getFeild("MTA_LOCATION_DESC_METHOD")?.id ||
                        getFeild("MTA_LOCATION_DESCRIPTION")?.id ||
                        getFeild("MTA_DIMENSIONS_BASE")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_ESTIMATION_SCOPE")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ESTIMATION_SCOPE")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_REASON")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_ESTIMATION_REASON")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_REASON_TEXT")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ESTIMATION_REASON_TEXT")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_BASE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_ESTIMATION_BASE")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_BASE_DESC")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ESTIMATION_BASE_DESC")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_METHOD")?.id && <div className="w100">
                                    <SelectMenu
                                        getTrxKey={getTrxKey}
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ESTIMATION_METHOD")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_METHOD_MULTI")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_ESTIMATION_METHOD_MULTI")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_STYLE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_ESTIMATION_STYLE")} />
                                </div>}
                                {getFeild("MTA_ESTIMATION_METHOD_DESC")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ESTIMATION_METHOD_DESC")} />
                                </div>}
                                {getFeild("MTA_VALUE_ASSUMPTION")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_VALUE_ASSUMPTION")} />
                                </div>}
                                {getFeild("MTA_RESEARCH_SCOPE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_RESEARCH_SCOPE")} />
                                </div>}
                                {getFeild("MTA_REPORT_USAGE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_REPORT_USAGE")} />
                                </div>}
                                {getFeild("MTA_DATA_SOURCE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_DATA_SOURCE")} />
                                </div>}
                                {getFeild("MTA_COPYRIGHTS")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_COPYRIGHTS")} />
                                </div>}
                                {getFeild("MTA_LOCATION_DESC_METHOD")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_LOCATION_DESC_METHOD")} />
                                </div>}
                                {getFeild("MTA_LOCATION_DESCRIPTION")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_LOCATION_DESCRIPTION")} />
                                </div>}
                                {getFeild("MTA_DIMENSIONS_BASE")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_DIMENSIONS_BASE")} />
                                </div>}
                            </div>
                        </div>}

                    {(getFeild("MTA_AFFECTED_FACTORS_1")?.id || getFeild("MTA_AFFECTED_FACTORS_2")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_AFFECTED_FACTORS_1")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_AFFECTED_FACTORS_1")} />
                                </div>}

                                {getFeild("MTA_AFFECTED_FACTORS_2")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_AFFECTED_FACTORS_2")} />
                                </div>}
                            </div>
                        </div>
                    }

                    {(getFeild("MTA_ASSUMPTIONS_1")?.id || getFeild("MTA_ASSUMPTIONS_2")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_ASSUMPTIONS_1")?.id && <div className="w100">
                                    <SelectMenu
                                        displayMultiLines
                                        getTrxKey={getTrxKey}
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ASSUMPTIONS_1")}
                                        className={style.selectMulti}
                                    />
                                </div>}

                                {getFeild("MTA_ASSUMPTIONS_2")?.id && <div className="w100">
                                    <InputText
                                        multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_ASSUMPTIONS_2")} />
                                </div>}
                            </div>
                        </div>
                    }

                    {(getFeild("MTA_RISK_ELEMENTS_1")?.id || getFeild("MTA_RISK_ELEMENTS_2")?.id) &&
                        <div className={style.generalEstPanel}>
                            <div className={`row ${style.generalEstRow} `}>
                                {getFeild("MTA_RISK_ELEMENTS_1")?.id && <div className="w100">
                                    <SelectMenu
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        feild={getFeild("MTA_RISK_ELEMENTS_1")} />
                                </div>}

                                {getFeild("MTA_RISK_ELEMENTS_2")?.id && <div className="w100">
                                    <InputText multiLines
                                        handleChange={handleChange}
                                        feild={getFeild("MTA_RISK_ELEMENTS_2")} />
                                </div>}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default GeneralEstimationBlock;