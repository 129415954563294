import Image from "@ipgd-gauge/image";
import Spinner from '@ipgd-gauge/spinner';
import Timer from '@ipgd-gauge/timer';
import { fixImagePath, formatMoney, hideWaiting, isEmpty, isEnglish, isNotEmpty, showWaiting } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slisting from "../../../components/slisting";
import TableChate from "../../../components/table-chate";
import ExecuteIconIcon from "../../../icons/ExecuteIcon";
import { executeListAction, getCurrentTime, getEstimationTransactionList } from "../../../util/apis";
import { ACTION_APPROVE, ACTION_CHANGE_PRIORITY, ACTION_CREATE, ACTION_EDIT_TRNX, ACTION_ESTIMATION, ACTION_ESTIMATION_QUALITY, ACTION_EXECUTE, ACTION_HANG_UP, ACTION_PRINT_PDF, ACTION_PRINT_WORD, ACTION_RECOVERY, ACTION_REVIEW, ACTION_VIEW, ACTION_VIEW_ESTIMATION_FORM, ACTION_VIEW_INSPECTION_FORM, ACTION_VIEW_REVISION_FORM, ESTIMATION_STATUS_CANCELLED, ESTIMATION_STATUS_HANGED_UP, ESTIMATION_STATUS_REJECTED } from '../../../util/constants';
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import { getToken } from "../../../util/cookies";
import { formatDateNTime } from "../../../util/dateUtil";
import MsgDialog from "../../common/dialogs/msg-dialog";
import SummaryDialog from "../../common/dialogs/summary-dialog";
import ChangePriority from "../dialogs/ChangePriority";
import ChooseReport from "../dialogs/ChooseReport";
import DivertToEstimator from "../dialogs/DivertToEstimator";
import EstimationRatingDialog from "../dialogs/EstimationRating";
import HangUp from "../dialogs/HangUp";
import PrintTransactionsDialog from "../dialogs/PrintTransactions";
import QrCodeDialog from "../dialogs/QrCodeDialog";
import RecoveryDialog from "../dialogs/Recovery";
import WorkFlowStatus from "../dialogs/WorkFlowStatus";
import Actions from "./Actions";
import RowActions from "./RowActions";
import style from './style.module.scss';
import RatesLogDialog from "../dialogs/RatesLogDialog";

const ACTIONS_VIEW = [ACTION_CREATE, ACTION_VIEW, ACTION_EDIT_TRNX, ACTION_ESTIMATION, ACTION_VIEW_INSPECTION_FORM, ACTION_VIEW_ESTIMATION_FORM, ACTION_VIEW_REVISION_FORM, ACTION_APPROVE, ACTION_REVIEW, ACTION_EXECUTE];

const _getDateAsString = (currentTime, date, t) => {
    let time = "";
    let trxDate = new Date(parseInt(date));
    let currentDate = new Date(currentTime);
    let differnece = currentDate - trxDate.getTime();
    let diffMinutes = parseInt(differnece / (60 * 1000) % 60);
    let diffHours = parseInt(differnece / (60 * 60 * 1000) % 24);
    let diffDays = parseInt(differnece / (24 * 60 * 60 * 1000));
    if (diffDays >= 8) {
        time = formatDateNTime(trxDate);
    } else if (diffDays == 1) {
        time = t("since") + " " + diffDays + " " + t("day_ago");
    } else if (diffDays < 8 && diffDays > 1) {
        time = t("since") + " " + diffDays + " " + t("days_ago");
    } else if (diffHours < 24 && diffHours > 0) {
        time = t("since") + " " + diffHours + " " + t("hours_ago");
    } else if (diffMinutes < 60 && diffMinutes > 0) {
        time = t("since") + " " + diffMinutes + " " + t("minutes_ago");
    } else if (diffMinutes > 0) {
        time = t("now");
    }
    return time;
}

const _getStatusColor = (status) => {
    switch (status) {
        case 'white':
            return '#000';
        case 'black':
            return '#fff';
        default:
            return '#fff';
    }
}

const Row = (props) => {
    const { data, rowActions } = props;
    const { summaryDialogRef, msgDialogRef, hangUpDialogRef, changePriorityDialog, divertToEstimatorDialogRef,
        workFlowStatusDialogRef, chooseReportDialogRef, recoveryDialogRef, estimationRatingDialogRef, serverUrl, user,
        executeListAction, qrCodeDialogRef, currentTimeRef, ratesLogDialogRef } = rowActions;

    const { t } = useTranslation('estimationTransaction');

    const [showMessages, setShowMessages] = useState(false);

    const _handleMouseOver = () => {
        if (!isMobile && document.getElementById(`execute-lbl-${data.sequence}`)) {
            document.getElementById(`row-status-${data.sequence}`).classList.add('active');
            document.getElementById(`status-lbl-${data.sequence}`).style.display = 'none';
            document.getElementById(`execute-lbl-${data.sequence}`).style.display = 'flex';
            document.getElementById(`ripple-${data.sequence}`).style.display = 'none';
        }
    }

    const _handleMouseLeave = () => {
        if (!isMobile && document.getElementById(`execute-lbl-${data.sequence}`)) {
            document.getElementById(`row-status-${data.sequence}`).classList.remove('active');
            document.getElementById(`status-lbl-${data.sequence}`).style.display = 'block';
            document.getElementById(`execute-lbl-${data.sequence}`).style.display = 'none';
            document.getElementById(`ripple-${data.sequence}`).style.display = 'flex';
        }
    }

    const _executeTransactionAction = () => {
        if (isEmpty(data.taskId) || data.status == ESTIMATION_STATUS_REJECTED || data.status == ESTIMATION_STATUS_CANCELLED || data.status == ESTIMATION_STATUS_HANGED_UP) {
            return;
        }
        const action = data.actionForExecute || ACTION_EXECUTE;
        const actionData = { sseId: data.taskId };
        if (action == ACTION_PRINT_PDF || action == ACTION_PRINT_WORD) {
            actionData.eriId = data.eriId;
            actionData.code = data.code;
        }
        executeListAction(action, data.id, actionData, () => { }, data.sequence)
    }

    return (
        <>
            <div className={`${style.estimationTransactionsRow} ${style.priorityStatusRow} trxRow`} onMouseOver={_handleMouseOver} onMouseLeave={_handleMouseLeave} style={{ '--priority-status': `var(--priority-status-${data.priority})` }}>
                <div className="cardRowMode" title={data.priority}>
                    <label className="nameLblMobile">#</label>
                    <label>{data?.sequence}</label>
                </div>
                <div className="hideRowActionMobile">
                    <div className="actionTableMobile" style={{ marginTop: '0' }}>
                        <RowActions data={data} showMessages={showMessages} setShowMessages={setShowMessages} ratesLogDialogRef={ratesLogDialogRef} />
                    </div>
                    <div className="hideActionMobile">
                        <RowActions data={data} showMessages={showMessages} setShowMessages={setShowMessages} ratesLogDialogRef={ratesLogDialogRef} />
                    </div>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label className={` ${style.code} ${style.isMobile}`} title={data.code || t('code_not_found')}>{data.code || t('code_not_found')}</label>
                        {data?.status != ESTIMATION_STATUS_CANCELLED && data?.status != ESTIMATION_STATUS_HANGED_UP && data.taskDateTime &&
                            <Timer
                                fromDate={currentTimeRef.current}
                                toDate={data.taskDateTime}
                                textColor={currentTimeRef.current < data.taskDateTime ? '#09a4f2' : '#ff0000'}
                                className={style.timerContainer}
                                showDays
                                showSeconds
                                showIcon
                                withMilliseconds
                                isCountdown={currentTimeRef.current < data.taskDateTime}
                            />
                        }
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:code')}</label>
                        <div title={data.code || t('code_not_found')}>{data.code || t('code_not_found')}</div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:task_date')}</label>
                        {data?.status != ESTIMATION_STATUS_CANCELLED && data?.status != ESTIMATION_STATUS_HANGED_UP && data.taskDateTime &&
                            <Timer
                                fromDate={currentTimeRef.current}
                                toDate={data.taskDateTime}
                                textColor={currentTimeRef.current < data.taskDateTime ? '#09a4f2' : '#ff0000'}
                                className={style.timerContainer}
                                showDays
                                showSeconds
                                showIcon
                                withMilliseconds
                                isCountdown={currentTimeRef.current < data.taskDateTime}
                            />
                        }
                    </div>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={data.refCode}>{data.refCode}</label>
                        <label title={_getDateAsString(currentTimeRef.current, data.dateTime, t)}>
                            {_getDateAsString(currentTimeRef.current, data.dateTime, t)}
                        </label>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:ref_num')}</label>
                        <div title={data.refCode}>{data.refCode}</div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:entry_date')}</label>
                        <div title={_getDateAsString(currentTimeRef.current, data.dateTime, t)}>
                            {_getDateAsString(currentTimeRef.current, data.dateTime, t)}
                        </div>
                    </div>
                </div>

                <div className="hideActionMobile">
                    <label className="nameLblMobile">{t('estimationTransaction:image')}</label>
                    <label className={`tableImgContainer without-label`}>
                        <span>
                            <Image
                                className="tableImg" src={data.smallLogo ? `${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.smallLogo)}` : './images/avatar.png'}
                            />
                        </span>
                    </label>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={isEnglish() ? data.officialNameLo : data.officialNameFo}>
                            {isEnglish() ? data.officialNameLo : data.officialNameFo}
                        </label>
                        <label title={data.clientName}>{`${data.clientName || ''}${data.orderNo ? `- ${data.orderNo}` : ''}`}</label>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:party')}</label>

                        <div title={isEnglish() ? data.officialNameLo : data.officialNameFo}>
                            {isEnglish() ? data.officialNameLo : data.officialNameFo}
                        </div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:customer')}</label>
                        <div title={data.clientName} className={style.clientContainer}>
                            <span>
                                <Image
                                    className="tableImg" src={data.smallLogo ? `${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.smallLogo)}` : './images/avatar.png'}
                                />
                            </span>
                            <label>
                                {data.clientName}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={isEnglish() ? data.townDescLo : data.townDescFo}>
                            {isEnglish() ? data.townDescLo : data.townDescFo}
                        </label>
                        <label title={isEnglish() ? data.districtDescLo : data.districtDescFo}>
                            {isEnglish() ? data.districtDescLo : data.districtDescFo}
                        </label>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:city')}</label>
                        <div title={isEnglish() ? data.townDescLo : data.townDescFo}>
                            {isEnglish() ? data.townDescLo : data.townDescFo}
                        </div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:town')}</label>
                        <div title={isEnglish() ? data.districtDescLo : data.districtDescFo}>
                            {isEnglish() ? data.districtDescLo : data.districtDescFo}
                        </div>
                    </div>
                </div>
                <div className="columnSm">
                    <div className="columnRowNew">
                        {isEnglish() ? data.descLo : data.descFo &&
                            <label title={isEnglish() ? data.descLo : data.descFo}>{isEnglish() ? data.descLo : data.descFo}</label>
                            ||
                            <label title={t('not_found')}>{t('not_found')}</label>
                        }
                        {user?.estimationPolicy?.osmSecureEstimationValue == "0" &&
                            <label title={formatMoney(data.evaluationPrice)}>{formatMoney(data.evaluationPrice)}</label>
                        }
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:property_type')}</label>
                        <div title={isEnglish() ? data.descLo : data.descFo} className="overflowTableField">
                            {isEnglish() ? data.descLo : data.descFo}
                        </div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:evaluation_value')}</label>
                        {user?.estimationPolicy?.osmSecureEstimationValue == "0" &&
                            <div title={formatMoney(data.evaluationPrice)} className="overflowTableField">
                                {formatMoney(data.evaluationPrice)}
                            </div>
                        }
                    </div>
                </div>
                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={isEnglish() ? data.enterdByNameLo : data.enterdByNameFo}>
                            {isEnglish() ? data.enterdByNameLo : data.enterdByNameFo}
                        </label>
                        <label title={isEnglish() ? data.inspectorNameLo : data.inspectorNameFo}>
                            {isEnglish() ? data.inspectorNameLo : data.inspectorNameFo}
                        </label>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:entered_by')}</label>
                        <div title={isEnglish() ? data.enterdByNameLo : data.enterdByNameFo} className="overflowTableField">
                            {isEnglish() ? data.enterdByNameLo : data.enterdByNameFo}
                        </div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:inspector')}</label>
                        <div title={isEnglish() ? data.inspectorNameLo : data.inspectorNameFo} className="overflowTableField">
                            {isEnglish() ? data.inspectorNameLo : data.inspectorNameFo}
                        </div>
                    </div>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        {isNotEmpty(isEnglish() ? data.estimatorNameLo : data.estimatorNameFo) || isNotEmpty(isEnglish() ? data.coordinatorNameLo : data.coordinatorNameFo) ?
                            <>
                                <label title={isEnglish() ? data.estimatorNameLo : data.estimatorNameFo}>
                                    {isEnglish() ? data.estimatorNameLo : data.estimatorNameFo}
                                </label>
                                <label title={isEnglish() ? data.coordinatorNameLo : data.coordinatorNameFo}>
                                    {isEnglish() ? data.coordinatorNameLo : data.coordinatorNameFo}
                                </label>
                            </>
                            :
                            <label title={t('not_found')}>{t('not_found')}</label>
                        }
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:estimator')}</label>
                        <div title={isEnglish() ? data.estimatorNameLo : data.estimatorNameFo} className="overflowTableField">
                            {isEnglish() ? data.estimatorNameLo : data.estimatorNameFo}
                        </div>
                    </div>
                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('estimationTransaction:coordinator')}</label>
                        <div title={isEnglish() ? data.coordinatorNameLo : data.coordinatorNameFo} className="overflowTableField">
                            {isEnglish() ? data.coordinatorNameLo : data.coordinatorNameFo}
                        </div>
                    </div>
                </div>
                <div className="columnSm">
                    <div className={`columnRowNew ${data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP ? style.clickable : ''}`}>
                        <div className={style.stepsContaner}>
                            {new Array(parseInt(data.messagesCount || 0)).fill("").map((n, index) => {
                                return (
                                    <div className={index + 1 <= parseInt(data.completedMsgCount) ? style.activeStep : style.step}></div>
                                )
                            })}
                        </div>
                        <div className={style.executeContainer}>
                            <label id={`row-status-${data.sequence}`}
                                className={style.listingStatus}
                                title={isEnglish() ? data.statusDescLo : data.statusDescFo}

                                style={{ background: isMobile ? 'transparent' : data.statusColor || '#63B3D3', color: isMobile ? data.statusColor || '#63B3D3' : _getStatusColor(data.statusColor) }}
                                onClick={_executeTransactionAction}
                            >
                                {data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP &&
                                    <div id={`ripple-${data.sequence}`} className={style.introBannerVdoPlayBtn}>
                                        <span className={style.ripple}></span>
                                        <span className={style.ripple}></span>
                                        <span className={style.ripple}></span>
                                    </div>
                                }

                                <span id={`status-lbl-${data.sequence}`} className={style.statusLblSm}>
                                    {isEnglish() ? data.statusDescLo : data.statusDescFo}
                                </span>
                                {data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP && !isMobile &&
                                    <span id={`execute-lbl-${data.sequence}`} style={{ display: 'none' }}>
                                        <Spinner id={`row-status-${data.sequence}-spinner`} className={style.spinner} />
                                        <ExecuteIconIcon />
                                        {t('execute')}
                                    </span>
                                }
                            </label>
                            {data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP &&
                                <button className={style.executeBtnMobile}
                                    onClick={_executeTransactionAction}>
                                    <ExecuteIconIcon />
                                    <Spinner id={`row-status-${data.sequence}-spinner`} className={style.spinner} />
                                    <label>{t('execute')}</label>
                                </button>
                            }
                        </div>
                    </div>

                    <div className="estimation-trx-status">
                        <label className="nameLblMobile">{t('estimationTransaction:status')}</label>
                        <div className={` ${data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP ? style.clickable : ''}`}>
                            <div className={style.stepsContaner}>
                                {new Array(parseInt(data.messagesCount || 0)).fill("").map((n, index) => {
                                    return (
                                        <div className={index + 1 <= parseInt(data.completedMsgCount) ? style.activeStep : style.step}></div>
                                    )
                                })}
                            </div>
                            <div className={style.executeContainer}>
                                <label id={`row-status-${data.sequence}-card`}
                                    className="active-card-trx"
                                    title={isEnglish() ? data.statusDescLo : data.statusDescFo}
                                >
                                    <>
                                        {data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP &&
                                            <div id={`ripple-${data.sequence}-card`} className={style.introBannerVdoPlayBtn}>
                                                <span className={style.ripple}></span>
                                                <span className={style.ripple}></span>
                                                <span className={style.ripple}></span>
                                            </div>
                                        }

                                        <span id={`status-lbl-${data.sequence}-card`} className={` ${style.statusLblSm} ${data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP ? 'withRipple' : ""}`} style={{ background: isMobile ? 'transparent' : data.statusColor || '#63B3D3', color: isMobile ? data.statusColor || '#63B3D3' : _getStatusColor(data.statusColor) }}>
                                            {isEnglish() ? data.statusDescLo : data.statusDescFo}
                                        </span>
                                    </>
                                    {data.taskId && data.status != ESTIMATION_STATUS_REJECTED && data.status != ESTIMATION_STATUS_CANCELLED && data.status != ESTIMATION_STATUS_HANGED_UP &&
                                        <span id={`execute-lbl-${data.sequence}-card`} onClick={_executeTransactionAction}>
                                            <Spinner id={`row-status-${data.sequence}-card-spinner`} className={style.spinner} />
                                            <ExecuteIconIcon />
                                            {t('execute')}
                                        </span>
                                    }
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="transaction-frm-action">
                    <div>
                        <Actions
                            user={user}
                            data={data}
                            msgDialogRef={msgDialogRef}
                            hangUpDialogRef={hangUpDialogRef}
                            changePriorityDialog={changePriorityDialog}
                            divertToEstimatorDialogRef={divertToEstimatorDialogRef}
                            executeListAction={executeListAction}
                            workFlowStatusDialogRef={workFlowStatusDialogRef}
                            chooseReportDialogRef={chooseReportDialogRef}
                            estimationRatingDialogRef={estimationRatingDialogRef}
                            recoveryDialogRef={recoveryDialogRef}
                            summaryDialogRef={summaryDialogRef}
                            qrCodeDialogRef={qrCodeDialogRef}
                        />
                    </div>
                </div>
            </div>
            {
                showMessages &&
                <TableChate transactionId={data.id} />
            }
        </>
    );
};

const EstimationTransactionsTable = (props) => {

    const { user, tableRef, estimationTransactionsFilterRef, searchTextRef, isTab } = props;

    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const { t } = useTranslation();
    const msgDialogRef = useRef();
    const hangUpDialogRef = useRef();
    const changePriorityDialog = useRef();
    const recoveryDialogRef = useRef();
    const summaryDialogRef = useRef();
    const divertToEstimatorDialogRef = useRef();
    const workFlowStatusDialogRef = useRef();
    const chooseReportDialogRef = useRef();
    const estimationRatingDialogRef = useRef();
    const printTransactionsDialogRef = useRef();
    const qrCodeDialogRef = useRef();
    const currentTimeRef = useRef();
    const ratesLogDialogRef = useRef();
    const navigate = useNavigate();

    const [transactions, setTransactions] = useState();
    const columns = [
        { title: '#', width: "3%" },
        { title: '', width: "9%" },
        { title: t('estimationTransaction:code'), width: "8%",/*  searchColumns: "TRI_CODE", */ sortBy: "TRI_CODE" },
        { title: t('estimationTransaction:ref_num'), width: "8%", sortBy: "TRI_DATE_TIME" },
        { title: "", width: "3%" },
        { title: t('estimationTransaction:customer'), width: "10%", sortBy: "ATR_OFFICIAL_NAME_LO, ATR_OFFICIAL_NAME_FO" },
        { title: t('estimationTransaction:city'), width: "9%", sortBy: "ECD_DESC_LO, ECD_DESC_FO" },
        { title: t('estimationTransaction:property_type'), width: "9%", sortBy: "MYO_DESC_LO, MYO_DESC_FO" },
        { title: t('estimationTransaction:entered_by_inspector'), width: "9%", sortBy: "ENTR.FUS_NAME_LO, ENTR.FUS_NAME_FO" },
        { title: t('estimationTransaction:estimator_coordinator'), width: "9%", sortBy: "ESTM.APR_NAME_LO, ESTM.APR_NAME_FO" },
        { title: t('estimationTransaction:status'), width: "10%", sortBy: "TRI_STATUS_DESC_LO, TRI_STATUS_DESC_FO" },
        { title: t('estimationTransaction:processes'), width: "13%" },
    ];

    const showExecuteActionWaiting = (show, seq) => {
        if (show) {
            if (document.getElementById(`row-status-${seq}`)) {
                document.getElementById(`row-status-${seq}`).classList.add('active-exec');
            }
            showWaiting(`row-status-${seq}-spinner`, true)
            showWaiting(null, true);
        } else {
            if (document.getElementById(`row-status-${seq}`)) {
                document.getElementById(`row-status-${seq}`).classList.remove('active-exec');
            }
            hideWaiting(`row-status-${seq}-spinner`);
            hideWaiting(null);
        }
    }

    const _executeListAction = (action, trnxId, actionData, onComplite, seq) => {
        if (action == ACTION_PRINT_WORD || action == ACTION_PRINT_PDF) {
            chooseReportDialogRef?.current?.open(action, trnxId, actionData.eriId, actionData.code, actionData.sseId, true);
            return;
        }
        if (action == ACTION_EXECUTE) {
            showExecuteActionWaiting(true, seq);
        } else {
            showWaiting(`transaction-action-btn-spinner-id-${action}-${trnxId}`, true)
        }
        return executeListAction({ action: action, trnxId: trnxId, token: getToken(), ...actionData })
            .then((response) => {
                if (ACTIONS_VIEW.includes(action)) {
                    navigate(`/estimation-trx?key=${response.data}`)
                }
                if (action != ACTION_ESTIMATION_QUALITY) {
                    tableRef?.current?.refresh();
                }
            })
            .catch(() => { })
            .finally(() => {
                if (onComplite) {
                    onComplite();
                }
                if (action == ACTION_EXECUTE) {
                    showExecuteActionWaiting(false, seq);
                } else {
                    hideWaiting(`transaction-action-btn-spinner-id-${action}-${trnxId}`)
                }
            })
    }

    const _creatTrx = () => {
        executeListAction({ action: ACTION_CREATE, trnxId: -1, token: getToken() })
            .then((response) => {
                navigate(`/estimation-trx?key=${response.data}`)
            })
    }

    const _newAction = () => {
        if (user.estimationPolicy?.actions?.indexOf(`${ACTION_CREATE}`) != 1) {
            return _creatTrx;
        }
        return null;
    }

    const _fetchData = (pageNumber, pageSize, sortBy, columnsSearch) => {
        const filterData = {
            searchWhereValue: searchTextRef.current.getSearchText(),
            sorting: sortBy,
            columnsSearch: columnsSearch,
            ...estimationTransactionsFilterRef?.current?.getFilterData()
        };
        getEstimationTransactionList(pageNumber, pageSize, filterData)
            .then((response) => setTransactions(response.data))
            .catch((error) => console.log("🚀 ~ error", error));

        getCurrentTime()
            .then((response) => currentTimeRef.current = response.data)
            .catch((error) => currentTimeRef.current = new Date().getTime());
    }

    const _hangUpAction = (id, data) => {
        showWaiting(`hangup-dialog-okButton`, true);
        _executeListAction(ACTION_HANG_UP, id, data, () => {
            hideWaiting(`hangup-dialog-okButton`);
            hangUpDialogRef.current?.close();
        })
    }

    const _changePriorityAction = (id, data) => {
        showWaiting(`change-priority-dialog-okButton`, true);
        _executeListAction(ACTION_CHANGE_PRIORITY, id, data, () => {
            hideWaiting(`change-priority-dialog-okButton`);
            changePriorityDialog.current?.close();
        })
    }

    const _divertAction = (id, action, data) => {
        showWaiting('divert-to-estimator-dialog-okButton', true);
        _executeListAction(action, id, data, () => {
            hideWaiting('divert-to-estimator-dialog-okButton');
            divertToEstimatorDialogRef.current?.close();
        })
    }

    const _recoveryAction = (id, data) => {
        showWaiting(`recoveryDialog-okButton`, true);
        _executeListAction(ACTION_RECOVERY, id, data, () => {
            hideWaiting(`recoveryDialog-okButton`);
            recoveryDialogRef.current?.close();
        })
    }

    const _ratingAction = (id, data, rateFromLog) => {
        showWaiting(`estimation-rating-dialog-okButton`, true);
        _executeListAction(ACTION_ESTIMATION_QUALITY, id, data, () => {
            hideWaiting(`estimation-rating-dialog-okButton`);
            estimationRatingDialogRef.current?.close();
            if (rateFromLog) {
                ratesLogDialogRef.current.refresh();
            } else {
                tableRef?.current?.refresh();
            }
        })
    }

    const _onExecutePrintAction = (printedSuccessfully, isPDF, transactionId, taskId) => {
        if (!printedSuccessfully || isEmpty(transactionId) || isEmpty(taskId)) {
            return;
        }
        return executeListAction({ action: isPDF ? ACTION_PRINT_PDF : ACTION_PRINT_WORD, trnxId: transactionId, token: getToken(), sseId: taskId })
            .then(() => tableRef?.current?.refresh())
            .catch(() => { });
    }

    return (
        <>
            <Slisting
                id="estimation-transactions-table"
                formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={`${style.estimationTransactionsTable} ${isTab ? style.estimationTransactionsTabTable : ''}`}
                getData={_fetchData}
                pagingData={transactions}
                withFilter
                rowActions={{
                    summaryDialogRef, msgDialogRef, hangUpDialogRef, changePriorityDialog, divertToEstimatorDialogRef,
                    workFlowStatusDialogRef, chooseReportDialogRef, recoveryDialogRef, estimationRatingDialogRef,
                    serverUrl, user, executeListAction: _executeListAction, qrCodeDialogRef, currentTimeRef, ratesLogDialogRef
                }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={_newAction()}
                cardInMobile
                // exportExcelAction={_exportExcelAction}
                withPrintAction
                labelCardWidth="120px"
                printAction={(ids) => printTransactionsDialogRef?.current?.open(ids)}
            />

            <PrintTransactionsDialog ref={printTransactionsDialogRef} />
            <SummaryDialog
                id="valuation-transactions-summary-dialog"
                ref={summaryDialogRef}
                formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            />
            <MsgDialog
                ref={msgDialogRef}
                tableRef={tableRef}
                formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
                fromTransactions={true}
            />
            <HangUp ref={hangUpDialogRef} okAction={_hangUpAction} />
            <ChangePriority ref={changePriorityDialog} okAction={_changePriorityAction} />
            <DivertToEstimator ref={divertToEstimatorDialogRef} okAction={_divertAction} />
            <WorkFlowStatus user={user} ref={workFlowStatusDialogRef} />
            <ChooseReport ref={chooseReportDialogRef} onComplete={_onExecutePrintAction} />
            <EstimationRatingDialog ref={estimationRatingDialogRef} rateAction={_ratingAction} />
            <RecoveryDialog ref={recoveryDialogRef} okAction={_recoveryAction} />
            <QrCodeDialog ref={qrCodeDialogRef} tableRef={tableRef} />
            <RatesLogDialog ref={ratesLogDialogRef} tableRef={tableRef} estimationRatingDialogRef={estimationRatingDialogRef} />
        </>
    );
};

export default EstimationTransactionsTable;