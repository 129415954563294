import Image from "@ipgd-gauge/image";
import Spinner from "@ipgd-gauge/spinner";
import { fixImagePath, formatDateNTime, formatMoney, handleError } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Modal from "../../../../components/modal";
import LocationMapIcon from "../../../../icons/LocationMap";
import UserMapIcon from "../../../../icons/UserMap";
import { executeListAction, getAttachments, getEstimationHistoryForSummary, getEstimationTransaction } from "../../../../util/apis";
import { ACTION_VIEW, ACTION_VIEW_ESTIMATION_FORM, ESTIMATION_HISTORY, ESTIMATION_TRANSACTION_ATTACHMENTS } from "../../../../util/constants";
import { getToken } from "../../../../util/cookies";
import style from './style.module.scss';

const SummaryDialog = (props, ref) => {

    const { id, formId, withView, postClose } = props

    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const { t } = useTranslation("estimationMap");
    const navigate = useNavigate();
    const dialogRef = useRef();

    const [data, setData] = useState({});
    const [images, setImages] = useState([]);
    const [showWaiting, setShowWaiting] = useState(true);

    const _postClose = () => {
        setData({})
        setImages([])
        setShowWaiting(true);
        if (postClose) {
            postClose();
        }
    }

    const open = (trnxId, estimationHistoryId) => {
        if (trnxId) {
            getEstimationTransaction(trnxId)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t))

            getAttachments(trnxId, ESTIMATION_TRANSACTION_ATTACHMENTS)
                .then((response) => {
                    if (response.data?.length > 0) {
                        setImages(response.data)
                    }
                    setShowWaiting(false)
                })
                .catch(() => {
                    setShowWaiting(false)
                })
        } else if (estimationHistoryId) {
            getEstimationHistoryForSummary(estimationHistoryId)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t))

            getAttachments(estimationHistoryId, ESTIMATION_HISTORY)
                .then((response) => {
                    if (response.data?.length > 0) {
                        setImages(response.data)
                    }
                    setShowWaiting(false)
                })
                .catch(() => {
                    setShowWaiting(false)
                })
        }
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <Modal
            id={id || "summary-dialog"}
            ref={dialogRef}
            formId={formId}
            title={t('view_transactions')}
            className={style.summaryDialog}
            withFooter
            withCancelButton
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={_postClose}
        >
            <div className={`container ${style.containersummaryDialog}`} >
                <div className='row rowSmModel'>
                    <div className={`w50`}>
                        <div className={style.summaryCodeNum}>
                            <label>{data.code}</label>
                        </div>
                        <div className={style.summaryPriceNum}>
                            <label>{formatMoney(data.evaluationPrice)}</label>
                            <span className={style.currencysummary}>{i18next.language == "en" ? data.currencyDescLo : data.currencyDescFo}</span>
                        </div>
                        <div className={style.date}>
                            <label>{formatDateNTime(data.dateTime)}</label>
                        </div>
                        <div className={style.summaryUser}>
                            <UserMapIcon />
                            <label>
                                {i18next.language == 'en' ? data.officialNameLo : data.officialNameFo}
                            </label>
                        </div>
                        <div className={style.summaryLocation}>
                            <LocationMapIcon />
                            <label>
                                {i18next.language == "en" ? data.addressLo : data.addressFo}
                            </label>
                        </div>
                        <div className={style.userInfoBox}>
                            <div className={style.areaSection}>
                                <div>
                                    <label className={style.lblName}>{t('land_area')}</label>
                                    <label className={style.lblVal}>
                                        {data.parcelArea}
                                        <span>{i18next.language == "en" ? 'M' : 'م'}<sup>2</sup></span>
                                    </label>
                                </div>
                                <div>
                                    <label className={style.lblName}>{t('price_of_one_meter')}</label>
                                    <label className={style.lblVal}>
                                        {formatMoney(data.parcelPricePerM)}
                                        <span>{i18next.language == "en" ? data.currencyDescLo : data.currencyDescFo}</span>
                                    </label>
                                </div>
                            </div>
                            <div className={style.buildingSection}>
                                <div>
                                    <label className={style.lblName}>{t('building_area')}</label>
                                    <label className={style.lblVal}>
                                        {data.totalBuildArea}
                                        <span>{i18next.language == "en" ? 'M' : 'م'}<sup>2</sup></span>
                                    </label>
                                </div>
                                <div>
                                    <label className={style.lblName}>{t('price_of_one_meter')}</label>
                                    <label className={style.lblVal}>
                                        {formatMoney(data.buildMeterValue)}
                                        <span>{i18next.language == "en" ? data.currencyDescLo : data.currencyDescFo}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w50">
                        <div className={style.containerGallery}>
                            <div className={style.galleryImg}>
                                {(data.image || !showWaiting) ?
                                    <Image src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.image || images[0]?.filePath)}`} />
                                    :
                                    <Spinner
                                        id="main-image-spinner"
                                        className={style.mainImgSpinner}
                                    />
                                }
                            </div>
                            <div className={style.multiImages}>
                                {images.map((image, index) => {
                                    return (
                                        <>
                                            <div
                                                className={style.imgs}
                                                onClick={() => {
                                                    data.image = image.filePath;
                                                    setData({ ...data })
                                                }} >
                                                <Image src={`${serverUrl}/apis/attachments/download-attachment/${image.id}/${ESTIMATION_TRANSACTION_ATTACHMENTS}`} />
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {withView && data.estimationTrxId &&
                    <button
                        onClick={() => {
                            executeListAction({ action: ACTION_VIEW_ESTIMATION_FORM, trnxId: data.estimationTrxId, token: getToken() })
                                .then((response) => navigate(`/estimation-trx?key=${response.data}`));
                        }}
                        title={t("view")} className="primary-button">
                        {t("view")}
                    </button>
                }
            </div>
        </Modal>
    )
}

export default forwardRef(SummaryDialog);