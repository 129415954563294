import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import DownLoadAllIcon from "../../../icons/DownLoadAll";
import NewWordIcon from "../../../icons/NewWord";
import { deleteEstimationPrintOut, downloadReports, downloadReportsSetup, getEstimationPrintOuts } from "../../../util/apis";
import { ESTIMATION_PRINT_OUT } from "../../../util/constants";
import { DELETE_ACTION, NEW_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { FND3168_REPORTS_DESIGN_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';
const Row = (props) => {
    const { t } = useTranslation();
    const { data, rowActions } = props;
    const { deleteAction, reportsDesignDialogRef } = rowActions;

    return (
        <div className={`${style.reportsDesignTableRow}`}>
            <div title={data.sequence} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>
            <div title={data.name} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('reportsDesign:report_name')}</label>
                <label>{data.name}</label>
            </div>
            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('reportsDesign:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>
            <div>
                <div className='containerButtonTable cardRowMode'>
                    {isAllowedAction(getUser(), FND3168_REPORTS_DESIGN_FORM_ID, VIEW_EDIT_ACTION) && <EditBtn onClick={() => reportsDesignDialogRef?.current?.open(data.id)} />}
                    {isAllowedAction(getUser(), FND3168_REPORTS_DESIGN_FORM_ID, DELETE_ACTION) && <DeleteBtn onClick={() => deleteAction(data.id)} />}
                </div>
            </div>
        </div>
    );
};

const ReportsDesignTable = (props) => {

    const { reportsDesignTableRef, searchTextRef, reportsDesignDialogRef, filterRef } = props;
    const { t } = useTranslation();

    const [reports, setReports] = useState();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('reportsDesign:report_name'), width: '50%' },
        { title: t('reportsDesign:status'), width: '28%' },
        { title: '', width: '17%' }
    ]

    const _fetchData = (pageNumber, pageSize) => {
        const filterData = { searchValue: searchTextRef.current.getSearchText(), ...filterRef.current?.getFilterData() }
        getEstimationPrintOuts(pageNumber, pageSize, filterData)
            .then((response) => setReports(response.data))
            .catch((error) => console.log("🚀 ~ error", error))
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimationPrintOut(id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    reportsDesignTableRef?.current?.refresh();
                })
                .catch(() => handleError(null, "delete_fields", t));
        });
    }

    return (
        <div className={style.reportsDesignTableContainer}>
            <Slisting
                id="reportsDesignTableRefTable"
                ref={reportsDesignTableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.reportsDesignTable}
                pagingData={reports}
                getData={_fetchData}
                rowActions={{ deleteAction: _deleteAction, reportsDesignDialogRef }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => data && data.length == 0}
                formId={FND3168_REPORTS_DESIGN_FORM_ID}
                user={getUser()}
                viewAction={(id) => reportsDesignDialogRef.current?.open(id)}
                deleteAction={deleteEstimationPrintOut}
                newAction={() => reportsDesignDialogRef.current?.open()}
                cardInMobile
                labelCardWidth="80px"
            // listingActions={[
            //     { id: `form-action-${NEW_ACTION}`, icon: <DownLoadAllIcon fill="var(--primary-color-2)" />, label: t('reportsDesign:download_all'), action: () => downloadReports(ESTIMATION_PRINT_OUT, `form-action-${NEW_ACTION}`) },
            //     { id: `form-action-${VIEW_EDIT_ACTION}`, icon: <NewWordIcon />, label: t('reportsDesign:download_word'), action: () => downloadReportsSetup(`form-action-${VIEW_EDIT_ACTION}`) },
            // ]}
            />
        </div>
    );
};

export default ReportsDesignTable;